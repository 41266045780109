var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "claim-form" },
    [
      _c("div", { staticClass: "d-flex form-header mb-4" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c("h2", { staticClass: "align-self-center pb-0 mb-0" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "align-self-center p-0 m-0 mx-2" },
            [
              !_vm.loadingForm
                ? _c(
                    "b-badge",
                    {
                      staticClass: "animate__animated animate__fadeIn",
                      attrs: { variant: "outline-info" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.openTeamWeek.open_week_name) +
                          "\n        "
                      ),
                    ]
                  )
                : _c("b-badge", { attrs: { variant: "light" } }, [
                    _vm._v("Loading week"),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "align-self-center p-0 m-0" },
            [
              !_vm.loadingForm
                ? _c(
                    "b-badge",
                    {
                      staticClass: "animate__animated animate__fadeIn",
                      attrs: { variant: "outline-new" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.openTeamWeek.open_day_name) +
                          "\n        "
                      ),
                    ]
                  )
                : _c("b-badge", { attrs: { variant: "light" } }, [
                    _vm._v("Loading day"),
                  ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml-auto d-flex" },
          [
            _c(
              "b-button",
              {
                staticClass: "ailgn-self-center",
                attrs: { size: "xs", variant: "light", disabled: _vm.disabled },
                on: { click: _vm.resetClaimData },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("lodgements.clear-button")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.disabled,
            rounded: "sm",
            variant: "white",
            opacity: "0.7",
            blur: "",
          },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _vm.loadingForm
                    ? _c(
                        "div",
                        {
                          key: "loading",
                          staticClass:
                            "waiting-game-loading text-center animate__animated animate__fadeIn",
                        },
                        [
                          _c("div", [
                            _c("img", {
                              staticClass: "mb-3",
                              attrs: {
                                src: "/assets/img/svg/claims/umrella_outline.svg",
                                alt: "waiting",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "text-center align-self-center mb-0",
                                },
                                [_vm._v("Loading")]
                              ),
                              _vm._v(" "),
                              _c("pulse-loader", {
                                staticClass: "align-self-center ml-2",
                                attrs: {
                                  loading: _vm.loadingForm,
                                  color: _vm.fill,
                                  size: "8px",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          key: "complete-loading",
                          staticClass:
                            "waiting-game-start text-center animate__animated animate__bounceIn",
                        },
                        [
                          _vm.status === 0
                            ? _c("div", { key: "game-not-start" }, [
                                _c("img", {
                                  staticClass: "mb-3",
                                  attrs: {
                                    src: "/assets/img/svg/claims/lunchbreak_outline.svg",
                                    alt: "waiting",
                                  },
                                }),
                                _vm._v(" "),
                                _c("h4", { staticClass: "text-center" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("lodgements.game-not-started")
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.status === 2
                            ? _c("div", { key: "game-complete" }, [
                                _c("img", {
                                  staticClass: "mb-3 completed",
                                  attrs: {
                                    src: "/assets/img/svg/claims/completedtask_outline.svg",
                                    alt: "completed",
                                  },
                                }),
                                _vm._v(" "),
                                _c("h4", { staticClass: "text-center" }, [
                                  _vm._v("Day has ended!"),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onClaimSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-row",
                { staticClass: "form-body" },
                [
                  _c(
                    "b-col",
                    { staticClass: "col-lg-6 col-md-12 mb-2" },
                    [
                      _c("base-input", {
                        key: "first-name-input",
                        attrs: {
                          id: "first-name-input",
                          label: _vm.$t("lodgements.first-name"),
                          placeholder: _vm.$t("lodgements.placeholder"),
                          validate: "",
                          required: "",
                          v: _vm.$v.newClaim.customerFirstName,
                          error: _vm.setError(
                            _vm.$v.newClaim.customerFirstName,
                            _vm.$t("lodgements.first-name")
                          ),
                          loading: _vm.loading,
                        },
                        model: {
                          value: _vm.newClaim.customerFirstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.newClaim, "customerFirstName", $$v)
                          },
                          expression: "newClaim.customerFirstName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "col-lg-6 col-md-12 mb-2" },
                    [
                      _c("base-input", {
                        key: "last-name-input",
                        attrs: {
                          id: "last-name-input",
                          label: _vm.$t("lodgements.last-name"),
                          placeholder: _vm.$t("lodgements.placeholder"),
                          validate: "",
                          required: "",
                          v: _vm.$v.newClaim.customerLastName,
                          error: _vm.setError(
                            _vm.$v.newClaim.customerLastName,
                            _vm.$t("lodgements.last-name")
                          ),
                          loading: _vm.loading,
                        },
                        model: {
                          value: _vm.newClaim.customerLastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.newClaim, "customerLastName", $$v)
                          },
                          expression: "newClaim.customerLastName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "col-lg-6 col-md-12 mb-2" },
                    [
                      _c("base-input", {
                        key: "policy-number-input",
                        attrs: {
                          id: "policy-number-input",
                          label: _vm.$t("lodgements.policy-number"),
                          placeholder: _vm.$t("lodgements.placeholder"),
                          validate: "",
                          required: "",
                          v: _vm.$v.newClaim.policyNumber,
                          error: _vm.setError(
                            _vm.$v.newClaim.policyNumber,
                            _vm.$t("lodgements.policy-number")
                          ),
                          loading: _vm.loading,
                        },
                        model: {
                          value: _vm.newClaim.policyNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.newClaim,
                              "policyNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "newClaim.policyNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "col-lg-6 col-md-12 mb-2" },
                    [
                      _c("base-selection", {
                        attrs: {
                          label: _vm.$t("lodgements.policy-type"),
                          placeholder: _vm.$t("lodgements.placeholder"),
                          itemValue: "name",
                          options: _vm.policyTypes.options,
                          validate: "",
                          required: "",
                          readonly: "",
                          v: _vm.$v.newClaim.policyType,
                          error: _vm.setError(
                            _vm.$v.newClaim.policyType,
                            _vm.$t("lodgements.policy-type")
                          ),
                          loading: _vm.loading,
                        },
                        model: {
                          value: _vm.selectedPolicyType,
                          callback: function ($$v) {
                            _vm.selectedPolicyType = $$v
                          },
                          expression: "selectedPolicyType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "col-lg-4 col-md-12 mb-2" },
                    [
                      _c("b-input-group", { staticClass: "mb-4" }, [
                        _c("label", { staticClass: "pl-0 mb-2 col-12" }, [
                          _vm._v(_vm._s(_vm.$t("lodgements.claim-decision"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content:
                                    _vm.$t("lodgements.claim-decision") +
                                    " is required",
                                  show:
                                    _vm.$v.newClaim.claimDecision.$dirty &&
                                    !_vm.$v.newClaim.claimDecision.required,
                                  trigger: "manual",
                                  placement: "bottom",
                                  classes: ["light"],
                                },
                                expression:
                                  "{\n                content: $t('lodgements.claim-decision') + ' is required',\n                show: $v.newClaim.claimDecision.$dirty && !$v.newClaim.claimDecision.required,\n                trigger: 'manual',\n                placement: 'bottom',\n                classes: ['light']\n              }",
                              },
                            ],
                            staticClass: "d-flex",
                          },
                          _vm._l(_vm.claimDecisions, function (item) {
                            return _c(
                              "b-button",
                              {
                                key: item.key,
                                staticClass: "mr-2",
                                attrs: {
                                  variant:
                                    _vm.newClaim.claimDecision === item.value
                                      ? item.variant
                                      : "light",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.newClaim.claimDecision = item.value
                                  },
                                },
                                model: {
                                  value: _vm.$v.newClaim.claimDecision.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.newClaim.claimDecision,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.newClaim.claimDecision.$model",
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "col-lg-4 col-md-12 mb-2" },
                    [
                      _c("base-input", {
                        key: "eligible-claim-input",
                        attrs: {
                          id: "eligible-claim-input",
                          label: _vm.$t("lodgements.eligible-claim-amount"),
                          placeholder: _vm.$t("lodgements.placeholder"),
                          type: "number",
                          focus: "",
                          min: "0",
                          oninput: "validity.valid||(value='');",
                          v: _vm.$v.newClaim.eligibleClaim,
                          error: _vm.setError(
                            _vm.$v.newClaim.eligibleClaim,
                            _vm.$t("lodgements.eligible-claim-amount")
                          ),
                          loading: _vm.loading,
                        },
                        model: {
                          value: _vm.newClaim.eligibleClaim,
                          callback: function ($$v) {
                            _vm.$set(_vm.newClaim, "eligibleClaim", $$v)
                          },
                          expression: "newClaim.eligibleClaim",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "col-lg-4 col-md-12 mb-2" },
                    [
                      _c("base-input", {
                        key: "excess-amount-input",
                        attrs: {
                          id: "excess-amount-input",
                          label: _vm.$t("lodgements.excess-amount"),
                          placeholder: _vm.$t("lodgements.placeholder"),
                          type: "number",
                          focus: "",
                          min: "0",
                          oninput: "validity.valid||(value='');",
                          v: _vm.$v.newClaim.excessClaim,
                          error: _vm.setError(
                            _vm.$v.newClaim.excessClaim,
                            _vm.$t("lodgements.excess-amount")
                          ),
                          loading: _vm.loading,
                        },
                        model: {
                          value: _vm.newClaim.excessClaim,
                          callback: function ($$v) {
                            _vm.$set(_vm.newClaim, "excessClaim", $$v)
                          },
                          expression: "newClaim.excessClaim",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "col-md-12 mb-2" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          !_vm.disableApprovalCode &&
                          _vm.approvalCodeValidation === 1
                            ? _c("base-input", {
                                key: `approval-code-input-${_vm.approvalCodeValidation}`,
                                attrs: {
                                  id: "approval-code-input",
                                  label: _vm.$t("lodgements.approval-code"),
                                  placeholder: _vm.$t("lodgements.placeholder"),
                                  validate: "",
                                  v: _vm.$v.newClaim.approvalCode,
                                  error: _vm.setError(
                                    _vm.$v.newClaim.approvalCode,
                                    _vm.$t("lodgements.approval-code")
                                  ),
                                  loading: _vm.loading,
                                },
                                model: {
                                  value: _vm.newClaim.approvalCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newClaim, "approvalCode", $$v)
                                  },
                                  expression: "newClaim.approvalCode",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex mt-3" }, [
                _c(
                  "div",
                  { staticClass: "d-flex ml-auto" },
                  [
                    _vm.locale
                      ? _c(
                          "h4",
                          { staticClass: "d-inline align-self-center mb-0" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("lodgements.customer-payment")) +
                                ": " +
                                _vm._s(
                                  _vm._f("formatPayment")(_vm.customerPayment)
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("processing-button", {
                      staticClass: "ml-3 align-self-center btn-shadow",
                      attrs: {
                        variant: "primary",
                        size: "sm",
                        label: "Submit",
                        processing: _vm.processing,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }