var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "policy-tip-page": true, shown: _vm.isOpen },
      on: { mouseenter: _vm.mouseEnter },
    },
    [
      _c(
        "b-row",
        [
          _vm._l(_vm.policiesPage, function (content, index) {
            return _c("b-col", { key: `content-modal-${index}` }, [
              _c(
                "div",
                {
                  staticClass: "text-center content-icon",
                  class: { active: _vm.selectedContentIndex === index },
                },
                [
                  _c(
                    "div",
                    {
                      key: `content-img-${index}`,
                      staticClass: "content-img-bg mx-auto",
                      on: {
                        click: function ($event) {
                          return _vm.onContentClick(index)
                        },
                      },
                    },
                    [_c("img", { attrs: { src: content.img, alt: "" } })]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "my-2 content-title" }, [
                    _vm._v(_vm._s(content.title)),
                  ]),
                ]
              ),
            ])
          }),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c(
              "div",
              {
                key: `content-body-${_vm.selectedContentIndex}`,
                staticClass: "mx-4 mt-4 policy-content",
              },
              [
                _c("div", { staticClass: "text-center mb-3" }, [
                  _c("blockquote", { staticClass: "m-0" }, [
                    _c("p", { staticClass: "policy-subtitle mb-2" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.policiesPage[_vm.selectedContentIndex].subtitle
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex policy-detail mb-2" }, [
                  _c("i", {
                    staticClass:
                      "iconsminds-light-bulb-2 align-self-center mr-2",
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "d-inline mb-0 align-self-center" }, [
                    _vm._v(_vm._s(_vm.$t("pages.policies.overview-title"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.policiesPage[_vm.selectedContentIndex].overview
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex policy-detail mb-2" }, [
                  _c("i", {
                    staticClass:
                      "iconsminds-information align-self-center mr-2",
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "d-inline mb-0 align-self-center" }, [
                    _vm._v(_vm._s(_vm.$t("pages.policies.covered-title"))),
                  ]),
                ]),
                _vm._v(" "),
                _vm.locale
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatContent(
                            _vm.policiesPage[_vm.selectedContentIndex].covered
                          )
                        ),
                      },
                    })
                  : _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.policiesPage[_vm.selectedContentIndex].covered
                        ),
                      },
                    }),
              ]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "policy-tip-page-button",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggle.apply(null, arguments)
            },
          },
        },
        [
          _c("span", [
            _c("i", {
              class: this.isOpen
                ? "simple-icon-arrow-right"
                : "simple-icon-arrow-left",
            }),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "align-middle",
            attrs: { src: "/assets/img/svg/claims/policy.svg", alt: "policy" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }