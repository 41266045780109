<template>
  <div class="main lodgements">
    <div class="container d-flex">
      <b-row class="h-100 align-items-center mx-auto my-auto">
        <b-col class="col-12 lodgement-container">
          <div class="d-flex">
            <div id="clock" class="pl-4 align-self-center">
              <timer class="time" :time="prettyTime" :warning="runningOutWarning"></timer>
            </div>
            <div class="align-self-center ml-auto d-flex">
              <div
                class="work-allocation align-self-center d-inline-block"
                v-tooltip="{
                  content:
                    workAllocationCount > 0
                      ? `${workAllocationCount} ${$t('lodgements.allocations-hover')}`
                      : `${$t('lodgements.allocations-hover-no-record')}`,
                  placement: 'bottom',
                  classes: ['light'],
                  offset: 10
                }"
              >
                <div
                  v-if="workAllocationCount > 0"
                  :key="`work-allocation-count-${reloadedWorkAllocations}`"
                  class="numberCircle animate__animated animate__bounceIn"
                >
                  <p class="mb-0 mx-auto">
                    {{ workAllocationCount }}
                  </p>
                </div>
                <router-link v-if="workAllocationCount > 0" to="/work/allocations" target="_blank">
                  <i class="fal fa-layer-group mr-2"></i>
                </router-link>
                <i v-else class="fal fa-layer-group mr-2 disabled"></i>
              </div>
              <div class="align-self-center d-inline-block mx-3">
                <img
                  src="/assets/img/svg/claims/policy.svg"
                  alt="policy"
                  @click="tipsMenuOver = !tipsMenuOver"
                  v-tooltip="{
                    content: `${$t('lodgements.policies-hover')}`,
                    placement: 'bottom',
                    classes: ['light'],
                    offset: 10
                  }"
                  class="align-self-center"
                />
              </div>
            </div>
          </div>
          <b-card no-body>
            <b-overlay :show="isCountingDown" rounded="sm" variant="dark" opacity="0.3" blur="">
              <b-card-body>
                <claim-form
                  :key="`claim-form-${reloadedClaimForm}`"
                  :startedAt="startedAt"
                  :disabled="disabledGame"
                  :status="gameStatus"
                  :loadingForm="loading"
                  :title="$t('lodgements.title')"
                ></claim-form>
              </b-card-body>
              <template #overlay>
                <div
                  :key="`game-start-countdown-${gameStartTimerCount}`"
                  class="count-down animate__animated animate__bounceIn"
                >
                  {{ gameStartTimerCount }}
                </div>
              </template>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>

      <policy-tip-page :menuOver="tipsMenuOver" @toggleClosed="tipsMenuOver = false" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import moment from 'moment'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Timer from '@/components/Common/Timer'
import ClaimForm from '@/components/Form/ClaimForm.vue'
import PolicyTipPage from '@/components/Pages/PolicyTipPage'
import { gameDuration } from '@/constants/config'
import { formatPayment } from '@/constants/config'
import { pageVisibilityMixin } from '@/mixins/pageVisibilityMixin'
import { pusherMixin } from '@/mixins/pusherMixin'
export default {
  components: {
    'v-select': vSelect,
    Timer,
    ClaimForm,
    'policy-tip-page': PolicyTipPage
  },
  data() {
    return {
      startedAt: null,
      startTime: false,
      loading: true,
      isRunning: false,
      minutes: 0,
      seconds: 0,
      time: 0,
      timer: null,
      gameStartTimer: null,
      gameStartTimerCount: 4,
      isCountingDown: false,
      currentGame: null,
      currentTeamWeek: null,
      currentWorkAllocations: null,
      disabledGame: true,
      localTimestamp: 0,
      runningOutWarning: false,
      gameStatus: 0,
      gameDuration,
      tipsMenuOver: false,
      reloadedWorkAllocations: 0,
      reloadedClaimForm: 0
    }
  },
  mixins: [validationMixin, pageVisibilityMixin, pusherMixin],
  methods: {
    ...mapActions([
      'fetchOpenTeamWeek',
      'fetchWorkAllocations',
      'fetchGame',
      'completeWorkAllocation',
      'updateApprovalCode',
      'resetTempClaim'
    ]),
    async startGameStartTimer(data) {
      let self = this
      await self.resetGameStartTimer()
      self.gameStartTimer = setInterval(() => {
        self.isCountingDown = true
        if (self.gameStartTimerCount > 1) {
          self.gameStartTimerCount--
        } else {
          clearInterval(self.gameStartTimer)
          self.currentGame = data
          self.isCountingDown = false
        }
      }, 1000)
    },
    async resetGameStartTimer() {
      let self = this
      self.isCountingDown = false
      clearInterval(self.gameStartTimer)
      self.gameStartTimerCount = 4
    },
    startTimer(timestampGap) {
      let self = this
      self.startTime = true
      self.startedAt = new Date()

      self.time = timestampGap
      self.isRunning = true
      if (!self.timer) {
        self.timer = setInterval(() => {
          if (self.time > 0) {
            self.time--
          } else {
            clearInterval(self.timer)
            self.reset()
            self.disabledGame = true
            self.gameStatus = 2
          }
        }, 1000)
      }
    },
    reset() {
      let self = this
      self.resetTimer()
      self.runningOutWarning = false
      self.gameStatus = 0
    },
    async resetTimer() {
      let self = this
      self.isRunning = false
      clearInterval(self.timer)
      self.timer = null
      self.time = 0
      self.seconds = 0
      self.minutes = 0
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss.000')
    },
    convertLocalTimestamp(date) {
      return moment.utc(date).local().format('X')
    },
    async getOpenTeamWeek() {
      let self = this
      await self.fetchOpenTeamWeek()
      self.currentTeamWeek = self.openTeamWeek
    },
    async getWorkAlloctions() {
      let self = this
      await self.fetchWorkAllocations(self.currentTeamWeek.open_week_id)
      self.currentWorkAllocations = self.workAllocations
    },
    async getGame() {
      let self = this
      await self.fetchGame(self.currentTeamWeek.open_day_id)
      self.currentGame = self.game
    },
    async getLodgementData() {
      let self = this
      self.loading = true
      await self.resetTimer()
      await self.getOpenTeamWeek()
      await self.getWorkAlloctions()
      await self.getGame()
      self.loading = false
    },
    async initiateWeekControlListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`week-control.${self.currentUser.teamId}`)
      await channel.bind(
        'week-control',
        () => {
          self.getLodgementData()
          self.resetTempClaim()
          self.reloadedClaimForm += 1
        },
        channel.unbind()
      )
    },
    async initiateDayControlListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`day-control.${self.currentUser.teamId}`)
      await channel.bind(
        'day-control',
        () => {
          self.getLodgementData()
        },
        channel.unbind()
      )
    },
    async initiateGameControlListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`game-control.${self.currentUser.teamId}`)
      await channel.bind(
        'game-control',
        (data) => {
          if (data.status === 1) {
            // start game kickoff countdown
            self.startGameStartTimer(data)
          } else {
            self.currentGame = data
            self.resetGameStartTimer()
          }
        },
        channel.unbind()
      )
    },
    async initiateWorkAllocationListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`work-allocation.${self.currentUser.id}`)
      await channel.bind(
        'work-allocation',
        (data) => {
          if (data.allocation) {
            self.getWorkAlloctions()
          }

          // check if claim is completed and update record
          if (data.claim) {
            self.completeWorkAllocation(data.claim)
          }
        },
        channel.unbind()
      )
    },
    async initiateGameSettingsListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`game-settings.${self.currentUser.teamId}`)
      await channel.bind(
        'game-settings',
        (data) => {
          const approvalCodeStatus = parseInt(data.approval_code)
          self.updateApprovalCode(approvalCodeStatus)
        },
        channel.unbind()
      )
    },
    async detectGameChange(game) {
      let self = this
      // get local unix timestamp
      self.localTimestamp = moment().unix()
      let timestampGap = self.localTimestamp - game.time
      let gameDuration = await self.gameDuration
      if (game.status === 0) {
        self.disabledGame = true
        self.gameStatus = 0
      } else if (game.status === 1) {
        // check if time gap is in game duration range and give 3s grace period
        if (timestampGap <= gameDuration && timestampGap > -3) {
          self.disabledGame = false
          // restart the timer based on remaining time
          await self.startTimer(gameDuration - timestampGap)
        } else {
          // close the game if time gap isn't in range
          self.disabledGame = true
          self.gameStatus = 2
        }
      } else {
        self.disabledGame = true
        self.gameStatus = 2
        await self.resetTimer()
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'workAllocations',
      'claims',
      'getCurrentWorkAllocation',
      'game',
      'openTeamWeek'
    ]),
    prettyTime() {
      let self = this
      if (self.time <= 10 && self.time > 0) {
        self.runningOutWarning = true
      } else {
        self.runningOutWarning = false
      }
      let time = self.time / 60
      let minutes = parseInt(time)
      let seconds = Math.round((time - minutes) * 60)
      return `${minutes}:${seconds}`
    },
    workAllocationCount() {
      let self = this
      if (_.has(self.currentWorkAllocations, 'claimMasters')) {
        const unsubmittedClaims = self.currentWorkAllocations.claimMasters.filter(
          (claim) => !claim.completed
        )
        return unsubmittedClaims.length
      } else {
        return 0
      }
    }
  },
  async mounted() {
    let self = this
    self.initiateWeekControlListener()
    self.initiateDayControlListener()
    self.initiateGameControlListener()
    self.initiateWorkAllocationListener()
    self.initiateGameSettingsListener()
  },
  filters: {
    formatPayment(value) {
      return formatPayment(value)
    }
  },
  async created() {
    let self = this
    await self.getLodgementData()
  },
  beforeDestroy() {
    let self = this
    clearInterval(self.timer)
    clearInterval(self.gameStartTimer)
    self.leaveChannels()
  },
  watch: {
    currentGame: {
      handler(game) {
        this.detectGameChange(game)
      },
      deep: true
    },
    workAllocationCount() {
      this.reloadedWorkAllocations += 1
    },
    async pageVisibilityTimeGap(newVal) {
      // wait for 3s to reload game status if page is idle
      if (newVal > 3000) {
        await this.getGame()
      }
    }
  }
}
</script>
