var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main lodgements" }, [
    _c(
      "div",
      { staticClass: "container d-flex" },
      [
        _c(
          "b-row",
          { staticClass: "h-100 align-items-center mx-auto my-auto" },
          [
            _c(
              "b-col",
              { staticClass: "col-12 lodgement-container" },
              [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pl-4 align-self-center",
                      attrs: { id: "clock" },
                    },
                    [
                      _c("timer", {
                        staticClass: "time",
                        attrs: {
                          time: _vm.prettyTime,
                          warning: _vm.runningOutWarning,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "align-self-center ml-auto d-flex" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content:
                                  _vm.workAllocationCount > 0
                                    ? `${_vm.workAllocationCount} ${_vm.$t(
                                        "lodgements.allocations-hover"
                                      )}`
                                    : `${_vm.$t(
                                        "lodgements.allocations-hover-no-record"
                                      )}`,
                                placement: "bottom",
                                classes: ["light"],
                                offset: 10,
                              },
                              expression:
                                "{\n                content:\n                  workAllocationCount > 0\n                    ? `${workAllocationCount} ${$t('lodgements.allocations-hover')}`\n                    : `${$t('lodgements.allocations-hover-no-record')}`,\n                placement: 'bottom',\n                classes: ['light'],\n                offset: 10\n              }",
                            },
                          ],
                          staticClass:
                            "work-allocation align-self-center d-inline-block",
                        },
                        [
                          _vm.workAllocationCount > 0
                            ? _c(
                                "div",
                                {
                                  key: `work-allocation-count-${_vm.reloadedWorkAllocations}`,
                                  staticClass:
                                    "numberCircle animate__animated animate__bounceIn",
                                },
                                [
                                  _c("p", { staticClass: "mb-0 mx-auto" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.workAllocationCount) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.workAllocationCount > 0
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/work/allocations",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fal fa-layer-group mr-2",
                                  }),
                                ]
                              )
                            : _c("i", {
                                staticClass: "fal fa-layer-group mr-2 disabled",
                              }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "align-self-center d-inline-block mx-3",
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: `${_vm.$t(
                                    "lodgements.policies-hover"
                                  )}`,
                                  placement: "bottom",
                                  classes: ["light"],
                                  offset: 10,
                                },
                                expression:
                                  "{\n                  content: `${$t('lodgements.policies-hover')}`,\n                  placement: 'bottom',\n                  classes: ['light'],\n                  offset: 10\n                }",
                              },
                            ],
                            staticClass: "align-self-center",
                            attrs: {
                              src: "/assets/img/svg/claims/policy.svg",
                              alt: "policy",
                            },
                            on: {
                              click: function ($event) {
                                _vm.tipsMenuOver = !_vm.tipsMenuOver
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-overlay",
                      {
                        attrs: {
                          show: _vm.isCountingDown,
                          rounded: "sm",
                          variant: "dark",
                          opacity: "0.3",
                          blur: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "overlay",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: `game-start-countdown-${_vm.gameStartTimerCount}`,
                                    staticClass:
                                      "count-down animate__animated animate__bounceIn",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.gameStartTimerCount) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "b-card-body",
                          [
                            _c("claim-form", {
                              key: `claim-form-${_vm.reloadedClaimForm}`,
                              attrs: {
                                startedAt: _vm.startedAt,
                                disabled: _vm.disabledGame,
                                status: _vm.gameStatus,
                                loadingForm: _vm.loading,
                                title: _vm.$t("lodgements.title"),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("policy-tip-page", {
          attrs: { menuOver: _vm.tipsMenuOver },
          on: {
            toggleClosed: function ($event) {
              _vm.tipsMenuOver = false
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }