<template>
  <div class="claim-form">
    <div class="d-flex form-header mb-4">
      <div class="d-flex">
        <h2 class="align-self-center pb-0 mb-0">
          {{ title }}
        </h2>
        <p class="align-self-center p-0 m-0 mx-2">
          <b-badge
            v-if="!loadingForm"
            variant="outline-info"
            class="animate__animated animate__fadeIn"
          >
            {{ openTeamWeek.open_week_name }}
          </b-badge>
          <b-badge v-else variant="light">Loading week</b-badge>
        </p>
        <p class="align-self-center p-0 m-0">
          <b-badge
            v-if="!loadingForm"
            variant="outline-new"
            class="animate__animated animate__fadeIn"
          >
            {{ openTeamWeek.open_day_name }}
          </b-badge>
          <b-badge v-else variant="light">Loading day</b-badge>
        </p>
      </div>
      <div class="ml-auto d-flex">
        <b-button
          size="xs"
          variant="light"
          :disabled="disabled"
          @click="resetClaimData"
          class="ailgn-self-center"
        >
          {{ $t('lodgements.clear-button') }}
        </b-button>
      </div>
    </div>
    <b-overlay :show="disabled" rounded="sm" variant="white" opacity="0.7" blur="">
      <b-form @submit.prevent="onClaimSubmit">
        <b-row class="form-body">
          <b-col class="col-lg-6 col-md-12 mb-2">
            <base-input
              id="first-name-input"
              key="first-name-input"
              :label="$t('lodgements.first-name')"
              :placeholder="$t('lodgements.placeholder')"
              v-model="newClaim.customerFirstName"
              validate
              required
              :v="$v.newClaim.customerFirstName"
              :error="setError($v.newClaim.customerFirstName, $t('lodgements.first-name'))"
              :loading="loading"
            ></base-input>
          </b-col>
          <b-col class="col-lg-6 col-md-12 mb-2">
            <base-input
              id="last-name-input"
              key="last-name-input"
              :label="$t('lodgements.last-name')"
              :placeholder="$t('lodgements.placeholder')"
              v-model="newClaim.customerLastName"
              validate
              required
              :v="$v.newClaim.customerLastName"
              :error="setError($v.newClaim.customerLastName, $t('lodgements.last-name'))"
              :loading="loading"
            ></base-input>
          </b-col>
          <b-col class="col-lg-6 col-md-12 mb-2">
            <base-input
              id="policy-number-input"
              key="policy-number-input"
              :label="$t('lodgements.policy-number')"
              :placeholder="$t('lodgements.placeholder')"
              v-model.trim="newClaim.policyNumber"
              validate
              required
              :v="$v.newClaim.policyNumber"
              :error="setError($v.newClaim.policyNumber, $t('lodgements.policy-number'))"
              :loading="loading"
            ></base-input>
          </b-col>
          <b-col class="col-lg-6 col-md-12 mb-2">
            <base-selection
              :label="$t('lodgements.policy-type')"
              :placeholder="$t('lodgements.placeholder')"
              v-model="selectedPolicyType"
              itemValue="name"
              :options="policyTypes.options"
              validate
              required
              readonly
              :v="$v.newClaim.policyType"
              :error="setError($v.newClaim.policyType, $t('lodgements.policy-type'))"
              :loading="loading"
            ></base-selection>
          </b-col>
          <b-col class="col-lg-4 col-md-12 mb-2">
            <b-input-group class="mb-4">
              <label class="pl-0 mb-2 col-12">{{ $t('lodgements.claim-decision') }}</label>
              <div
                class="d-flex"
                v-tooltip="{
                  content: $t('lodgements.claim-decision') + ' is required',
                  show: $v.newClaim.claimDecision.$dirty && !$v.newClaim.claimDecision.required,
                  trigger: 'manual',
                  placement: 'bottom',
                  classes: ['light']
                }"
              >
                <b-button
                  v-for="item in claimDecisions"
                  :key="item.key"
                  :variant="newClaim.claimDecision === item.value ? item.variant : 'light'"
                  v-model="$v.newClaim.claimDecision.$model"
                  @click="newClaim.claimDecision = item.value"
                  class="mr-2"
                  >{{ item.name }}</b-button
                >
              </div>
            </b-input-group>
          </b-col>
          <b-col class="col-lg-4 col-md-12 mb-2">
            <base-input
              id="eligible-claim-input"
              key="eligible-claim-input"
              :label="$t('lodgements.eligible-claim-amount')"
              :placeholder="$t('lodgements.placeholder')"
              type="number"
              focus
              min="0"
              oninput="validity.valid||(value='');"
              v-model="newClaim.eligibleClaim"
              :v="$v.newClaim.eligibleClaim"
              :error="setError($v.newClaim.eligibleClaim, $t('lodgements.eligible-claim-amount'))"
              :loading="loading"
            ></base-input>
          </b-col>
          <b-col class="col-lg-4 col-md-12 mb-2">
            <base-input
              id="excess-amount-input"
              key="excess-amount-input"
              :label="$t('lodgements.excess-amount')"
              :placeholder="$t('lodgements.placeholder')"
              type="number"
              focus
              min="0"
              oninput="validity.valid||(value='');"
              v-model="newClaim.excessClaim"
              :v="$v.newClaim.excessClaim"
              :error="setError($v.newClaim.excessClaim, $t('lodgements.excess-amount'))"
              :loading="loading"
            ></base-input>
          </b-col>
          <b-col class="col-md-12 mb-2">
            <transition name="fade">
              <base-input
                v-if="!disableApprovalCode && approvalCodeValidation === 1"
                id="approval-code-input"
                :key="`approval-code-input-${approvalCodeValidation}`"
                :label="$t('lodgements.approval-code')"
                :placeholder="$t('lodgements.placeholder')"
                v-model="newClaim.approvalCode"
                validate
                :v="$v.newClaim.approvalCode"
                :error="setError($v.newClaim.approvalCode, $t('lodgements.approval-code'))"
                :loading="loading"
              ></base-input>
            </transition>
          </b-col>
        </b-row>
        <div class="d-flex mt-3">
          <div class="d-flex ml-auto">
            <h4 v-if="locale" class="d-inline align-self-center mb-0">
              {{ $t('lodgements.customer-payment') }}: {{ customerPayment | formatPayment }}
            </h4>
            <processing-button
              variant="primary"
              size="sm"
              label="Submit"
              class="ml-3 align-self-center btn-shadow"
              :processing="processing"
            ></processing-button>
          </div>
        </div>
      </b-form>
      <template #overlay>
        <div
          v-if="loadingForm"
          key="loading"
          class="waiting-game-loading text-center animate__animated animate__fadeIn"
        >
          <div>
            <img src="/assets/img/svg/claims/umrella_outline.svg" alt="waiting" class="mb-3" />
          </div>
          <div class="d-flex">
            <h4 class="text-center align-self-center mb-0">Loading</h4>
            <pulse-loader
              :loading="loadingForm"
              :color="fill"
              size="8px"
              class="align-self-center ml-2"
            ></pulse-loader>
          </div>
        </div>
        <div
          v-else
          key="complete-loading"
          class="waiting-game-start text-center animate__animated animate__bounceIn"
        >
          <div v-if="status === 0" key="game-not-start">
            <img src="/assets/img/svg/claims/lunchbreak_outline.svg" alt="waiting" class="mb-3" />
            <h4 class="text-center">{{ $t('lodgements.game-not-started') }}</h4>
          </div>
          <div v-if="status === 2" key="game-complete">
            <img
              src="/assets/img/svg/claims/completedtask_outline.svg"
              alt="completed"
              class="mb-3 completed"
            />
            <h4 class="text-center">Day has ended!</h4>
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required, minLength, minValue } = require('vuelidate/lib/validators')
import { formMixin } from '@/mixins/formMixin'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
import { formatPayment } from '@/constants/config'
export default {
  components: {
    PulseLoader
  },
  props: {
    title: {
      type: String,
      default: 'Claim Lodgement'
    },
    startedAt: {
      type: Date
    },
    disabled: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: 0
    },
    loadingForm: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validationMixin, formMixin],
  data() {
    return {
      fill: variables.primaryColor,
      newClaim: this.creatNewClaim(),
      selectedPolicyType: null
    }
  },
  validations() {
    let self = this
    if (!self.disableApprovalCode && self.approvalCodeValidation === 1) {
      return {
        newClaim: {
          customerFirstName: {
            required,
            nameMinLength: minLength(2)
          },
          customerLastName: {
            required,
            nameMinLength: minLength(2)
          },
          policyNumber: {
            required
          },
          policyType: {
            required
          },
          claimDecision: {
            required
          },
          eligibleClaim: {
            minValue: minValue(-0.001)
          },
          excessClaim: {
            minValue: minValue(-0.001)
          },
          approvalCode: {
            required
          }
        }
      }
    } else {
      return {
        newClaim: {
          customerFirstName: {
            required,
            nameMinLength: minLength(2)
          },
          customerLastName: {
            required,
            nameMinLength: minLength(2)
          },
          policyNumber: {
            required
          },
          policyType: {
            required
          },
          claimDecision: {
            required
          },
          eligibleClaim: {
            minValue: minValue(-0.001)
          },
          excessClaim: {
            minValue: minValue(-0.001)
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['submitClaim', 'updateWorkAlloction', 'updateTempClaim', 'resetTempClaim']),
    creatNewClaim() {
      return {
        customerFirstName: '',
        customerLastName: '',
        policyNumber: '',
        policyType: '',
        claimDecision: null,
        teamLeaderApprovalRequired: null,
        eligibleClaim: 0,
        excessClaim: 0,
        approvalCode: ''
      }
    },
    async onClaimSubmit() {
      let self = this
      self.$v.newClaim.$touch()

      // return if form error is captured
      if (self.$v.newClaim.$pending || self.$v.newClaim.$error) return

      // submit form
      let newClaim = self.newClaim

      // get work allocation
      let currentWorkAllocation = self.getCurrentWorkAllocation(newClaim.policyNumber)
      let currentWorkAllocationId = currentWorkAllocation ? currentWorkAllocation.id : 0

      // get day id
      let dayId = self.openTeamWeek.open_day_id

      let claim = {
        userId: self.currentUser.id,
        workAllocationId: self.workAllocations.id,
        weekId: self.workAllocations.teamWeekId,
        dayId: dayId,
        originalClaimId: currentWorkAllocationId,
        customerFirstName: newClaim.customerFirstName,
        customerLastName: newClaim.customerLastName,
        policyNumber: newClaim.policyNumber,
        policyType: newClaim.policyType,
        claimDecision: newClaim.claimDecision,
        teamLeaderApprovalRequired: self.customerPayment > 10000 ? true : false,
        eligibleClaim: newClaim.eligibleClaim,
        excessAmount: newClaim.excessClaim,
        approvalCode: newClaim.approvalCode,
        startedAt: self.formatDate(self.startedAt ? self.startedAt : new Date()),
        completedAt: self.formatDate(new Date())
      }

      self.processing = true
      await self.submitClaim(claim)
      self.processing = false

      await self.resetClaimData()
    },
    onCancelClick() {},
    async resetClaimData() {
      console.log('reset claim data')
      let self = this
      self.newClaim = self.creatNewClaim()
      await self.resetTempClaim()
      self.selectedPolicyType = null
      self.$v.$reset()
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'workAllocations',
      'claims',
      'openTeamWeek',
      'getCurrentWorkAllocation',
      'approvalCodeValidation',
      'tempClaim',
      'locale'
    ]),
    disableApprovalCode() {
      let self = this
      return self.newClaim.eligibleClaim - self.newClaim.excessClaim <= 10000 ? true : false
    },
    customerPayment() {
      let self = this
      return self.newClaim.eligibleClaim - self.newClaim.excessClaim
    }
  },
  filters: {
    formatPayment: function (value) {
      return formatPayment(value)
    }
  },
  async created() {
    let self = this
    if (self.tempClaim) {
      self.newClaim = self.tempClaim
    } else {
      self.newClaim = self.creatNewClaim()
    }
  },
  watch: {
    selectedPolicyType(newVal) {
      if (newVal) {
        this.newClaim.policyType = newVal.name
      }
    },
    newClaim: {
      deep: true,
      handler(newVal) {
        this.updateTempClaim(newVal)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.waiting-game-loading {
  img {
    width: 8rem;
  }
}
.waiting-game-start {
  img {
    width: 12rem;

    &.completed {
      width: 10rem;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.fade-enter-active {
  transition-delay: 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  // animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
